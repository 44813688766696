import Navbar2 from "../../Componenets/navbar/navbar2";
import Kayttoehdot from '../../Componenets/Kayttoehdot/Kayttoehdot';
import Footer2 from "../../Componenets/Footer/Footer2";

const KayttoehdotPage = () => {
    return (

    <>
    <Navbar2/>
    <Kayttoehdot/>
    <Footer2/>
    </>



    );
}

export default KayttoehdotPage;