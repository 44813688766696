import Navbar2 from "../../Componenets/navbar/navbar2";
import Footer2 from "../../Componenets/Footer/Footer2";
import Tietosuoja from '../../Componenets/Tietosuojakaytanto/Tietosuojakaytanto';

const TietosuojakaytantoPage = () => {
    return (
    <>
    <Navbar2/>
    <Tietosuoja/>
    <Footer2/>
    </>
    );
}

export default TietosuojakaytantoPage;