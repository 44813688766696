import Navbar2 from "../../Componenets/navbar/navbar2";
import Tuote1 from '../../Componenets/Tuotteet/1_Tuote';
import Footer2 from '../../Componenets/Footer/Footer2';

const Tuote_1 = () => {
    return (

    <>
    <Navbar2/>
    <Tuote1/>
    <Footer2/>
    </>



    );
}

export default Tuote_1;