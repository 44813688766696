import Navbar2 from "../../Componenets/navbar/navbar2";
import EULA from '../../Componenets/EULA/Eula';
import Footer2 from '../../Componenets/Footer/Footer2';

const EULAsivu = () => {
    return (

    <>
    <Navbar2/>
    <EULA/>
    <Footer2/>
    </>



    );
}

export default EULAsivu;