import Navbar2 from "../../Componenets/navbar/navbar2";
import Oikeudellisettiedot from '../../Componenets/Oikeudellisettiedot/Oikeudellisettiedot';
import Footer2 from '../../Componenets/Footer/Footer2';

const OikeudellisettiedotSivu = () => {
    return (

    <>
    <Navbar2/>
    <Oikeudellisettiedot/>
    <Footer2/>
    </>



    );
}

export default OikeudellisettiedotSivu;