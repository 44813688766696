import Navbar2 from "../../Componenets/navbar/navbar2";
import NotFound from '../../Componenets/NotFound/404';
import Footer2 from '../../Componenets/Footer/Footer2';

const EULAsivu = () => {
    return (

    <>
    <Navbar2/>
    <NotFound/>
    <Footer2/>
    </>



    );
}

export default EULAsivu;